import { config } from "../../configuration/app/app-config"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getTokenFromSessionStorage } from "../common/auth"
import { FreightForwarderInfo } from "../../model/freight-forwarder-info/freightForwarderInfo"
import { z } from "zod"

const FREIGHT_FORWARDER_INFO_API_TAG = "FREIGHT_FORWARDER_INFO"

const freightForwarderInfoRestSchema = z.object({
    duns_numbers: z.array(z.string())
})

type FreightForwarderInfoRest = z.infer<typeof freightForwarderInfoRestSchema>

const freightForwarderInfoBaseUrl = config.shipmentTrackingApi.baseUrl

const toFreightForwarderInfo = (payload: FreightForwarderInfoRest): FreightForwarderInfo => ({
    dunsNumbers: payload.duns_numbers,
})

export const freightForwarderInfoApi = createApi({
    reducerPath: "freightForwarderInfoApi",
    tagTypes: [FREIGHT_FORWARDER_INFO_API_TAG],
    baseQuery: fetchBaseQuery({
        baseUrl: freightForwarderInfoBaseUrl,
        prepareHeaders: async (headers) => {
            headers.set("authorization", `Bearer ${await getTokenFromSessionStorage()}`)
            headers.set("accept", "application/json")
            return headers
        },
    }),
    endpoints: (builder) => ({
        getFreightForwarderInfo: builder.query<FreightForwarderInfo, void>({
            query: () => "/freight-forwarder",
            transformResponse: (response: unknown) => {
                const freightForwarderInfoRest = freightForwarderInfoRestSchema.parse(response)
                return toFreightForwarderInfo(freightForwarderInfoRest)
            },
            providesTags: [{ type: FREIGHT_FORWARDER_INFO_API_TAG }],
        }),
    }),
})

export const {
    useGetFreightForwarderInfoQuery,
} = freightForwarderInfoApi
