import {
    ExternalArticle,
    ExternalArticleEmpties,
    ExternalCountryOfOrigin,
    ExternalDimension,
    ExternalGoods,
    ExternalGoodsType,
    ExternalHandlingUnit,
    ExternalHandlingUnitEmpties,
    ExternalPackable,
    ExternalPackableType
} from "../client/transportorder-hub/goods.model"
import {
    Article,
    BulkFreight,
    CargoFreight,
    CountryOfOrigin,
    Freight,
    HandlingUnit,
    HandlingUnitDimension,
    Packable
} from "../model/transportorder/transportOrder"
import {
    LengthMeasurement,
    LengthMeasurementUnit,
    VolumeMeasurement,
    VolumeMeasurementUnit,
    WeightMeasurement,
    WeightMeasurementUnit
} from "../model/transportorder/measurement"
import { convertExternalDangerousGoodsInformation } from "./transportOrderBundleConverter"
import { v4 as uuid } from "uuid"

export const convertExternalFreight = (src: ExternalGoods): Freight => {
    switch (src.type) {
    case ExternalGoodsType.CARGO_MATERIAL:
        const cargoMaterialResult: CargoFreight = {
            type: "CARGO",
            gross_weight: {
                value: calculateGrossWeightKg(src.handling_units),
                unit: WeightMeasurementUnit.KGM,
            },
            gross_volume: {
                value: calculateGrossVolumeM3(src.handling_units),
                unit: VolumeMeasurementUnit.MTQ
            },
            handling_units: src.handling_units.map(convertExternalHandlingUnit),
        }
        return cargoMaterialResult
    case ExternalGoodsType.CARGO_EMPTIES:
        const cargoEmptiesResult: CargoFreight = {
            type: "CARGO",
            gross_weight: {
                value: calculateGrossWeightEmptiesKg(src.handling_units),
                unit: WeightMeasurementUnit.KGM,
            },
            gross_volume: {
                value: calculateGrossVolumeM3(src.handling_units),
                unit: VolumeMeasurementUnit.MTQ
            },
            handling_units: src.handling_units.map(convertExternalHandlingUnitEmpties),
        }
        return cargoEmptiesResult
    case ExternalGoodsType.BULK_DRY:
    case ExternalGoodsType.BULK_LIQUID:
        const bulkResult: BulkFreight = {
            type: src.type,
            article_id: src.article_id,
            dangerous_goods_information: convertExternalDangerousGoodsInformation(src.dangerous_goods_information),
            gross_volume: convertToVolumeMeasurementM3(src.volume_m3),
            gross_weight: convertToWeightMeasurementKg(src.weight_kg)
        }
        return bulkResult
    }
}

const calculateGrossWeightKg = (handlingUnits: ExternalHandlingUnit[]): number => {
    let weightInKg = 0
    handlingUnits.forEach(handlingUnit => {
        const handlingUnitWeight = handlingUnit.weight
        if (handlingUnitWeight) {
            weightInKg += (handlingUnit.quantity ?? 1) * (handlingUnitWeight.gross_weight_kg ?? 0)
        }
    })
    return weightInKg
}

const calculateGrossWeightEmptiesKg = (handlingUnits: ExternalHandlingUnitEmpties[]): number => {
    let weightInKg = 0
    handlingUnits.forEach(handlingUnit => {
        weightInKg += (handlingUnit.quantity ?? 1) * (handlingUnit.gross_weight_kg ?? 0)
    })
    return weightInKg
}

const calculateGrossVolumeM3 = (handlingUnits: ExternalHandlingUnit[] | ExternalHandlingUnitEmpties[]): number => {
    let volumeInMtq = 0
    handlingUnits.forEach(handlingUnit => {
        const dimension = handlingUnit.dimension
        if (dimension) {
            const volumeOfOneHandlingUnit = (dimension.width_m ?? 0) * (dimension.height_m ?? 0) * (dimension.length_m ?? 0)
            volumeInMtq += (handlingUnit.quantity ?? 1) * volumeOfOneHandlingUnit
        }
    })
    return volumeInMtq
}

const convertExternalHandlingUnit = (src: ExternalHandlingUnit): HandlingUnit => {
    return {
        type: "HANDLING_UNIT",
        _frontendKey: uuid(),
        quantity: src.quantity,
        packaging_material_id: src.packaging_code,
        dimension: convertExternalDimension(src.dimension),
        weight: convertToWeightMeasurementKg(src.weight?.gross_weight_kg),
        stacking_factor: src.stacking_factor === undefined ? 1 : src.stacking_factor,
        content: src.content.map(convertExternalPackable)
    }
}

const convertExternalHandlingUnitEmpties = (src: ExternalHandlingUnitEmpties): HandlingUnit => {
    return {
        type: "HANDLING_UNIT",
        _frontendKey: uuid(),
        quantity: src.quantity,
        packaging_material_id: src.packaging_code,
        dimension: convertExternalDimension(src.dimension),
        weight: convertToWeightMeasurementKg(src.gross_weight_kg),
        stacking_factor: src.stacking_factor === undefined ? 1 : src.stacking_factor,
        content: src.content.map(convertExternalArticleEmpties)
    }
}

const convertExternalCountyOfOrigin = (src?: ExternalCountryOfOrigin): CountryOfOrigin | undefined => {
    return src ? {
        country_code: src.country_code,
        dutiable_goods: src.dutiable_goods,
    } : undefined
}

const convertExternalArticle = (src: ExternalArticle): Article => {
    return {
        _frontendKey: uuid(),
        type: src.type,
        article_id: src.id,
        quantity: src.quantity?.value,
        dangerous_goods_information: convertExternalDangerousGoodsInformation(src.dangerous_goods_information),
        net_explosive_mass: convertToWeightMeasurementKg(src.net_explosive_mass_kg),
        internal_place_of_destination: src.internal_place_of_destination?.id,
        country_of_origin: convertExternalCountyOfOrigin(src.country_of_origin),
    }
}

const convertExternalArticleEmpties = (src: ExternalArticleEmpties): Article => {
    return {
        _frontendKey: uuid(),
        type: "ARTICLE",
        article_id: src.id,
        quantity: src.quantity,
        dangerous_goods_information: convertExternalDangerousGoodsInformation(src.dangerous_goods_information),
        country_of_origin: undefined,
    }
}

const convertExternalPackable = (src: ExternalPackable): Packable => {
    switch (src.type) {
    case ExternalPackableType.ARTICLE:
        return convertExternalArticle(src)
    case ExternalPackableType.HANDLING_UNIT:
        return convertExternalHandlingUnit(src)
    }
}

const convertExternalDimension = (src?: ExternalDimension): HandlingUnitDimension | undefined => {
    return src ? {
        length: convertToLengthMeasurementM(src.length_m),
        width: convertToLengthMeasurementM(src.width_m),
        height: convertToLengthMeasurementM(src.height_m)
    } : undefined
}

const convertToLengthMeasurementM = (value?: number): LengthMeasurement | undefined => {
    return value ? {
        unit: LengthMeasurementUnit.MTR,
        value: value
    } : undefined
}

const convertToWeightMeasurementKg = (src: number | undefined): WeightMeasurement | undefined => {
    return src ? {
        unit: WeightMeasurementUnit.KGM,
        value: src,
    } : undefined
}

const convertToVolumeMeasurementM3 = (value?: number): VolumeMeasurement | undefined => {
    return value ? {
        unit: VolumeMeasurementUnit.MTQ,
        value: value
    } : undefined
}
