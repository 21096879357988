export interface ConfigState {
    useApiMocks: boolean
    skipLogin?: boolean
    mockAccessToken?: string
    isProd: boolean
    isDev: boolean
    mode: string
    sentry: {
        dsn: string
    }
    configCat: {
        sdkKey: string
    }
    transportOrderApi: {
        baseUrl: string
        pollIntervalMillis: number
    }
    asnMatchingApi: {
        baseUrl: string
    }
    mfhApi: {
        baseUrl: string
    }
    notificationSettingsApi: {
        baseUrl: string
    }
    productActivationApi: {
        baseUrl: string
    }
    agreementApi: {
        baseUrl: string
    }
    shipmentTrackingApi: {
        baseUrl: string
    }
    closingDaysFrontend: {
        url: string
    }
    assetAdminFrontend: {
        url: string
    }
    pocketDriverApp: {
        playStoreUrl: string
        appStoreUrl: string
    }
    contactForm: {
        url: string
    }
    release: {
        revision: string
        builtAt: string
    }
    login: {
        authority: string
        clientId: string
        preventRedirect: boolean
        redirectUri: string
    }
}

export const config: ConfigState = {
    useApiMocks: import.meta.env.VITE_USE_API_MOCKS === "true",
    skipLogin: import.meta.env.VITE_AUTH_SKIP_LOGIN === "true",
    mockAccessToken: import.meta.env.VITE_AUTH_MOCK_ACCESS_TOKEN,
    isProd: import.meta.env.PROD,
    isDev: import.meta.env.DEV,
    mode: import.meta.env.MODE,
    sentry: {
        dsn: import.meta.env.VITE_SENTRY_DSN,
    },
    configCat: {
        sdkKey: import.meta.env.VITE_CONFIGCAT_API_KEY,
    },
    transportOrderApi: {
        baseUrl: import.meta.env.VITE_HUB_API_GATEWAY,
        pollIntervalMillis: parseInt(import.meta.env.VITE_TRANSPORTORDERS_POLLING_INTERVAL_MILLIS ?? "60000"),
    },
    asnMatchingApi: {
        baseUrl: import.meta.env.VITE_ASN_MATCHING_API,
    },
    mfhApi: {
        baseUrl: import.meta.env.VITE_MFH_API,
    },
    notificationSettingsApi: {
        baseUrl: import.meta.env.VITE_TRANSPORTORDER_NOTIFICATION_SETTINGS_API
    },
    productActivationApi: {
        baseUrl: import.meta.env.VITE_DISPATCH_PRODUCT_ACTIVATION_API,
    },
    agreementApi: {
        baseUrl: import.meta.env.VITE_DISPATCH_TOS_API,
    },
    shipmentTrackingApi: {
        baseUrl: import.meta.env.VITE_SHIPMENT_TRACKING_API,
    },
    closingDaysFrontend: {
        url: import.meta.env.VITE_CLOSING_DAYS_URL,
    },
    assetAdminFrontend: {
        url: import.meta.env.VITE_ASSET_ADMINISTRATION_URL,
    },
    pocketDriverApp: {
        playStoreUrl: import.meta.env.VITE_POCKET_DRIVER_APP_PLAYSTORE_URL,
        appStoreUrl: import.meta.env.VITE_POCKET_DRIVER_APP_APPSTORE_URL,
    },
    contactForm: {
        url: import.meta.env.VITE_DUNS_CONTACT_URL,
    },
    release: {
        revision: import.meta.env.VITE_BUILD_COMMIT,
        builtAt: import.meta.env.VITE_BUILD_TIMESTAMP,
    },
    login: {
        authority: import.meta.env.VITE_AUTH_AUTHORITY,
        clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
        preventRedirect: import.meta.env.VITE_AUTH_PREVENT_REDIRECT === "true",
        redirectUri: import.meta.env.VITE_CALLBACK_URI_WEBTMS,
    },
}
