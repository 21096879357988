import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import Dialog from "@rio-cloud/rio-uikit/Dialog"

interface FreightPaymentDetailsDialogProps {
    showDialog: boolean
    onClose: () => void
}

export const FreightPaymentDetailsDialog = (props: FreightPaymentDetailsDialogProps): ReactElement => {
    const { onClose, showDialog } = props

    const dialogBody = (
        <>
            <div>
                Body
            </div>
        </>
    )

    const dialogFooter = (
        <>
            <div>
                Footer
            </div>
        </>
    )

    return (
        <Dialog
            title={<FormattedMessage id="freightPaymentDetails.dialog.title"/>}
            body={dialogBody}
            footer={dialogFooter}
            onClose={onClose}
            footerClassName={"display-flex"}
            show={showDialog}
        />
    )
}